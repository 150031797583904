var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"crm b-segment"},[(_vm.segmentsData.length)?_c('div',{staticClass:"crm__header b-segment__header"},[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('p',{staticClass:"crm__header-title title-m-bold neutral-900--text"},[_vm._v(" Сегменты ")])]),_c('v-col',[_c('base-text-field',{staticStyle:{"min-width":"225px"},attrs:{"prepend-inner-icon":'$iconify_search-outlined',"placeholder":"Быстрый поиск","escape-clearable":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"crm__header_new-btn",attrs:{"color":"primary"},on:{"click":_vm.createSidePanel}},[_c('iconify-icon',{attrs:{"icon":"plus-circle","width":"21"}}),_c('p',{staticClass:"body-m-semibold neutral-100--text"},[_vm._v(" Новый сегмент ")])],1)],1)],1)],1):_vm._e(),(!_vm.segmentsData.length)?_c('empty-segment',{attrs:{"side-panel-status":_vm.sidePanelStatus},on:{"update:sidePanelStatus":function($event){_vm.sidePanelStatus=$event},"update:side-panel-status":function($event){_vm.sidePanelStatus=$event}}}):_vm._e(),(_vm.segmentsData.length)?_c('base-table',{attrs:{"custom-filter":_vm.filterName,"search":_vm.search,"class-name":"table-segment","headers":_vm.tableHeaders,"items":_vm.segmentsData,"word-operations":['сегмент', 'сегмента', 'сегментов']},on:{"click:row":function (item) { return _vm.$router.push({name: 'ProgramAccounts', query: {segmentId: item.id}}); }},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-s-medium mb-0"},[_vm._v(" "+_vm._s(item.id)+" ")])]}},{key:"item.is_public",fn:function(ref){
var item = ref.item;
return [(item.is_public)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-eye ")]):_c('v-icon',[_vm._v(" mdi-eye-off ")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-s-semibold mb-0",staticStyle:{"display":"inline-block","padding":"4px 8px 4px 8px","border-radius":"4px","max-width":"50rem","word-break":"break-word"},style:(item.color != undefined ? ("color: " + (item.color) + "; background: " + (_vm.hexToRgbA(item.color, '0.15'))) : '')},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.profit",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-s-medium mb-0"},[_vm._v(" "+_vm._s(item.profit)+" ")])]}},{key:"item.client-cost",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-s-medium mb-0"},[_vm._v(" "+_vm._s((item.client_cost) ? item.client_cost : 0)+" ₽ ")])]}},{key:"item.profit_str",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-s-medium mb-0"},[_vm._v(" "+_vm._s((item.profit_str).toLocaleString(undefined, {style: _vm.program.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: _vm.program.currency.alpha3}))+" ")])]}},{key:"item.average_profit_str",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"body-s-medium mb-0"},[_vm._v(" "+_vm._s((item.average_profit_str).toLocaleString(undefined, {style: _vm.program.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: _vm.program.currency.alpha3}))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openSegmentClients(item)}}},[_c('v-list-item-title',[_vm._v("Клиенты")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.editSidePanel(item)}}},[_c('v-list-item-title',[_vm._v("Редактировать")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteUsersSegment(item)}}},[_c('v-list-item-title',[_vm._v("Очистить")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.deleteSegment(item)}}},[_c('v-list-item-title',[_vm._v("Удалить")])],1)],1)],1)]}}],null,true)}):_vm._e(),_c('side-panel-new-segment',{attrs:{"mode":_vm.sidePanelStatus.mode,"table-data":_vm.sidePanelStatus.data},model:{value:(_vm.sidePanelStatus.active),callback:function ($$v) {_vm.$set(_vm.sidePanelStatus, "active", $$v)},expression:"sidePanelStatus.active"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }