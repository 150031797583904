<template>
  <div class="crm b-segment">
    <div
      v-if="segmentsData.length"
      class="crm__header b-segment__header"
    >
      <v-row
        justify="space-between"
        align="center"
      >
        <v-col>
          <p class="crm__header-title title-m-bold neutral-900--text">
            Сегменты
          </p>
        </v-col>

        <v-col>
          <base-text-field
            v-model.trim="search"
            :prepend-inner-icon="'$iconify_search-outlined'"
            placeholder="Быстрый поиск"
            style="min-width: 225px"
            escape-clearable
            hide-details
            clearable
          />
        </v-col>

        <v-col cols="auto">
          <v-btn
            class="crm__header_new-btn"
            color="primary"
            @click="createSidePanel"
          >
            <iconify-icon
              icon="plus-circle"
              width="21"
            />
            <p
              class="body-m-semibold neutral-100--text"
            >
              Новый сегмент
            </p>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <empty-segment
      v-if="!segmentsData.length"
      :side-panel-status.sync="sidePanelStatus"
    />
    <base-table
      v-if="segmentsData.length"
      :custom-filter="filterName"
      :search="search"
      class-name="table-segment"
      :headers="tableHeaders"
      :items="segmentsData"
      :word-operations="['сегмент', 'сегмента', 'сегментов']"
      @click:row="(item) => $router.push({name: 'ProgramAccounts', query: {segmentId: item.id}})"
    >
      <template #[`item.id`]="{ item }">
        <p class="body-s-medium mb-0">
          {{ item.id }}
        </p>
      </template>
       <template #[`item.is_public`]="{ item }">
        <v-icon
          v-if="item.is_public"
          color="success"
        >
          mdi-eye
        </v-icon>
        <v-icon v-else>
          mdi-eye-off
        </v-icon>
      </template>    
      <template #[`item.name`]="{ item }">
        <p
          class="body-s-semibold mb-0"
          style="display: inline-block; padding: 4px 8px 4px 8px; border-radius: 4px;max-width: 50rem;word-break: break-word;"
          :style="item.color != undefined ? `color: ${item.color}; background: ${hexToRgbA(item.color, '0.15')}` : ''"
        >
          {{ item.name }}
        </p>
      </template>
      <template #[`item.profit`]="{ item }">
        <p class="body-s-medium mb-0">
          {{ item.profit }}
        </p>
      </template>
      <template #[`item.client-cost`]="{ item }">
        <p class="body-s-medium mb-0">
          {{ (item.client_cost) ? item.client_cost : 0 }} ₽
        </p>
      </template>
      <template #[`item.profit_str`]="{ item }">
        <p class="body-s-medium mb-0">
          {{ (item.profit_str).toLocaleString(undefined, {style: program.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: program.currency.alpha3}) }} 
        </p>
      </template>
      <template #[`item.average_profit_str`]="{ item }">
        <p class="body-s-medium mb-0">
          {{ (item.average_profit_str).toLocaleString(undefined, {style: program.currency.alpha3 !=='XXX' ? 'currency': 'decimal' , currency: program.currency.alpha3}) }} 
        </p>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-dots-vertical </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="openSegmentClients(item)">
              <v-list-item-title>Клиенты</v-list-item-title>
            </v-list-item>
            <v-list-item @click="editSidePanel(item)">
              <v-list-item-title>Редактировать</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteUsersSegment(item)">
              <v-list-item-title>Очистить</v-list-item-title>
            </v-list-item>
            <v-list-item @click="deleteSegment(item)">
              <v-list-item-title>Удалить</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </base-table>
    <side-panel-new-segment
      v-model="sidePanelStatus.active"
      :mode="sidePanelStatus.mode"
      :table-data="sidePanelStatus.data"
    />
  </div>
</template>

<script>
  import EmptySegment from './Empty'
  import SidePanelNewSegment from './components/SidePanelNewSegment'
  import Convertor from '@/mixins/convertor'
  import FormatNumber from '@/mixins/formatNumber'
  import FormatNumberFloat from '@/mixins/formatNumberFloat'

  export default {
    components: {
      EmptySegment,
      SidePanelNewSegment,
    },
    mixins: [Convertor, FormatNumber, FormatNumberFloat],
    data () {
      return {
        search: '',
        loadingList: false,
        sidePanelStatus: {
          active: false,
          mode: 'create',
          data: null,
        },
        tableHeaders: [
          { text: 'ID', align: 'start', value: 'id', sortable: true },
          { text: ' ', align: 'center', value: 'is_public', width: '1em', sortable: false},
          { text: 'Название сегмента', align: 'start', value: 'name', sortable: true },
          { text: 'Клиенты', align: 'end', value: 'clients_count', sortable: true },
          { text: 'Доход', align: 'end', value: 'profit_str', sortable: true },
          { text: 'Средний доход', align: 'end', value: 'average_profit_str', sortable: true },
          // { text: 'Клиент стоит', align: 'end', value: 'client-cost' },
          { text: '', align: 'end', value: 'actions',  width: '1em',},
        ],
      }
    },
    computed: {
      program () {
        return this.$store.getters['company/program/program']
      },
      segmentsData: {
        get: function () {
          return this.$store.getters['crm/segment/segments']
        },
        set: function (newValue) {
          this.segmentsData = newValue
        },
      },
    },
    watch: {
      program (v) {
        if (v) this.fetchData()
      },
    },
    created () {
      this.fetchData()
    },
    methods: {
      openSegmentClients (segment) {
        this.$router.push({ name: 'ProgramAccounts', query: { segmentId: segment.id } })
      },
      filterName (value, search, item) {
        return item.name.toString().indexOf(search) !== -1
      },
      createSidePanel (item) {
        this.sidePanelStatus.mode = 'create'
        this.sidePanelStatus.data = null
        this.sidePanelStatus.active = true
      },
      editSidePanel (item) {
        this.sidePanelStatus.mode = 'edit'
        this.sidePanelStatus.data = item
        this.sidePanelStatus.active = true
      },
      async fetchData () {
        try {
          this.loadingList = true
          const payload = {
            program_id: this.program.id,
          }
          await this.$store.dispatch('crm/segment/segments', payload)
        } finally {
          this.loadingList = false
        }
      },
      async deleteSegment (item) {
        try {
          this.loadingDelete = true
          await this.$store.dispatch('crm/segment/deleteSegment', {
            segment_id: item.id,
          })

          this.$notify({
            type: 'success',
            title: 'Сегменты',
            text: 'Сегмент успешно удален',
          })
        } finally {
          this.loadingDelete = false
        }
      },
      async deleteUsersSegment (item) {
        try {
          await this.$alert(
            'Исключить всех клиентов из данного сегмента?',
            this.$t('Исключение клиентов из сегмента'),
            {
              confirmButtonText: 'Да',
              showCancelButton: true,
              cancelButtonText: 'Отмена',
              type: 'warning',
            },
          )

          this.loadingDelete = true
          const payload = {
            segment_id: item.id,
          }
          await this.$store.dispatch('crm/segment/deleteUsersSegment', payload)
        } finally {
          this.loadingDelete = false
        }
      },
    },
  }
</script>

<style lang="scss">
@import "@/styles/vuetify-preset-plus/light_theme/crm/_crm.scss";
</style>
